import {Box, Typography, useMediaQuery} from "@mui/material";
import exIntro01 from "../../../assets/images/home/introduction/welcome-section-image-01.jpg";
import exIntro02 from "../../../assets/images/home/introduction/welcome-section-image-02.jpg";
import {commonStyles} from "../../style/commonStyle";

export const ExtendedIntroduction = (props) => {
    const classes = commonStyles()
    const minWidth600 = useMediaQuery('(min-width:600px)');
    return (
        <div data-aos="fade-in" style={{
            margin: '2rem auto 3rem auto',
            display: "flow-root",
            width: "70%",
            padding: '1rem',
            borderRadius: "1rem",
            borderTop: '5px solid ' +
                '#004829',
        }}
        >

            <Box
                sx={{
                    display: 'block',
                }}
            >
                <img data-aos="fade-left"
                    style={{
                        float: 'right',
                        marginLeft: '5rem',
                        marginBottom: '1rem',
                        position: "relative",
                        shapeMargin: '2rem',
                        shapeOutside: `url(${exIntro01})`,
                    }}
                    src={exIntro01}
                />
                <img data-aos="fade-left"
                    style={{
                        float: 'right',
                        marginLeft: '5rem',
                        right: "-100px",
                        position: "relative",
                        shapeMargin: '2rem',
                        shapeOutside: `url(${exIntro02})`,
                    }}
                    src={exIntro02}
                />
            </Box>
            <p data-aos="fade-in"
                style={{
                    fontFamily: 'FMSamantha',
                    lineHeight: 2.5,
                    fontSize: "1.2rem",
                    textAlign: 'justify',
                    color: "#575757",
                }}
            >
                iemj;a uki hkq ;ukag ys;lr" wkqkag ys;lr" bkak ;ekg ys;lr" fuf,djg ys;lr" mrf,djg ys;lr" kqjKe;a;kaf.a .eryqug ,la fkdjk whqßka fohla yßhg ie,iqï lr ksjerÈj l%shd;aul lr iEu md¾Yajhlgu hym;la jk whqßka ta foa ksjerÈj ksud lsÍfuka miqj ;uka l, lghq;a; .ek kej; úuid n,d ;ud w;ska yefudagu hym;la jQ njg wjfndaOhlska hq;=j ys;g we;s jk Woafõ.lr fkdjk iekis,s odhl mskd hEuhs'
            </p>

            <p data-aos="fade-in"
                style={{
                    fontFamily: 'FMSamantha',
                    lineHeight: 2.5,
                    fontSize: "1.2rem",
                    textAlign: 'justify',
                    color: "#575757",
                }}
            >
                yd;amiska me;sreKq wjfndaOhlska hqla; jQ m%idohla ;j;a mqoa.,fhl=f.a yojf;ys cks; jk whqßka ;ukag ys;lr" wkqkag ys;lr" bkak ;ekg ys;lr" fuf,djg ys;lr" mrf,djg ys;lr ksjk ms‚i mj;skakdjQ lghq;=" kqjKe;a;kaf.a .eryqug ,lafkdjk whqßka iSñ; jQ iïm;a Wmßu ld¾hlaIu;djhla ,efnk mßÈ yd;amiska úu¾IKhg fldg ksjerÈj ie,iqï lr id¾:lj l%shd;aul lsÍu ;=,ska l=i,h yd wl=i,h hk folu blaujd hk Woafõ.lr fkdjk iekis,s odhl udkisl uÜgula ;u ika;dkhka ys Wmojd .ekSu wruqKq fldgf.k mdidÈl fikiqka yd mokï oduh ks¾udKh lr we;'
            </p>
        </div>
    );
}