import './assets/css/App.css';
import * as React from "react";

import MainLayout from "./components/ui/layout/mainLayout";

import AOS from 'aos'
import 'aos/dist/aos.css';

AOS.init({
    offset: 120, // offset (in px) from the original trigger point
    delay: 0, // values from 0 to 3000, with step 50ms
    duration: 1500, // values from 0 to 3000, with step 50ms
    easing: 'ease-in', // default easing for AOS animations
    once: false, // whether animation should happen only once - while scrolling down
    mirror: true, // whether elements should animate out while scrolling past them
    anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
});
function App() {

    return (
        <div className="App">
            <MainLayout/>
        </div>
    );
}

export default App;
