import {Card, CardActionArea, CardContent, CardMedia} from "@mui/material";
import {useNavigate} from "react-router-dom";
import { API_CONFIGS } from "../../../config";


export const CommonCard = (props) => {
    const navigate = useNavigate()
    const handleOnClick = () => {
        navigate(props.path)
    }
    return (
        <Card sx={{
            maxWidth: 345,
            minHeight: 260,
            margin: '1rem', minWidth: 345,
            boxShadow: `0px 2px 10px rgba(0, 62, 200, 0.5)`
        }}>
            <CardActionArea
                onClick={handleOnClick}
            >
                <CardMedia
                    component="img"
                    height="140"
                    image={API_CONFIGS.fileBaseUrl + '' + props.thumbnail}
                />
                <CardContent>
                    <b>
                        <span
                            style={{
                                fontSize: '1.5rem',
                                fontFamily: "FMArjunn",
                                color: '#4d4d4d',
                            }}
                        >
                            {props.title}
                        </span>
                    </b>
                    {/*<Typography variant="body2" color="text.secondary">
                        {props.title}
                    </Typography>*/}
                </CardContent>
            </CardActionArea>
        </Card>
    )
}