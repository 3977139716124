import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import IconButton from "@mui/material/IconButton";
import {useEffect, useState} from "react";


const ScrollToTopButton = (
    {showBelow}
) => {
    const [show, setShow] = useState(!showBelow)
    const handleScroll = () => {
        if (window.scrollY > showBelow) {
            if (!show) setShow(true)
        } else {
            if (show) setShow(false)
        }
    }
    useEffect(() => {
        if (showBelow) {
            window.addEventListener(`scroll`, handleScroll)
            return () => window.removeEventListener(`scroll`, handleScroll)
        }
    }, [show]);
    const handleClick = () => {
        window[`scrollTo`]({top: 0, behavior: `smooth`})
    }
    return (
        <div>
            {show && (
                <IconButton onClick={handleClick}
                            sx={{
                                height:'48px',
                                width:'48px',
                                opacity:'0.5',
                                zIndex: '1',
                                position: 'fixed',
                                bottom: '6vh',
                                backgroundColor: "#DCDCDC",
                                color: 'black',
                                "&:hover, &.Mui-focusVisible": {
                                    transition: '0.4s',
                                    color: '#383845',
                                    backgroundColor: '#B37D51',
                                    opacity:'1'
                                },
                                right: '5%',

                            }}>
                    <ExpandLessIcon/>
                </IconButton>
            )}
        </div>)
}
export default ScrollToTopButton