import {useMediaQuery} from "@mui/material";
import {useParams} from "react-router-dom";
import {PageBodyContent} from "../../ui/inngerPageContent/pageBodyContent";
import {useEffect, useState} from "react";
import {loadFoundationById} from "../../../client/foundation/call";
import {LoginWrapper} from "../../ui/layout/loginWrapper";
import {FloatingCircle} from "../../ui/animation/floatingCircle";
import { PageLayout } from "../../ui/layout/pageLayout";

export default function Foundations() {
    const minWidth600 = useMediaQuery('(min-width:600px)');

    const params = useParams()
    const [foundation, setFoundation] = useState(null);
    const [id, setId] = useState("");

    useEffect(() => setId(params.id), [params]);

    useEffect(() => {
        if (id) {
            loadFoundationById((result) => {
                    setFoundation(result.content)
                },
                error => {

                }, id)
        }
    }, [id])

    const handleOnLoginSuccess = () => {
        loadFoundationById((result) => {
                setFoundation(result.content)
            },
            error => {

            }, id)
    }

    return (
        <PageLayout header={foundation ? foundation.name : ""} sx={{}}>
            <LoginWrapper
                callback={handleOnLoginSuccess}
            >
                <FloatingCircle/>
                {foundation && (
                    <PageBodyContent parentId={id}
                                     containers={foundation.webPageContents}
                                     parentType={"FOUNDATION"}
                    />
                )}
            </LoginWrapper>
        </PageLayout>
    );
}