import {
    Box, Button,
} from "@mui/material";

export const CommonButtonFormatting = (props) => {

    const { title, fontFamily, position, color, variant, size, href, margin } = props.data

    return (
        <div data-aos={props.transition}>
            <Box sx={{display: {xs: 'none', md: 'flex', justifyContent: position, margin: margin}}}>
                <Button sx={{
                    textTransform: 'lowercase',
                    fontFamily: {fontFamily}
                }}
                        color={color}
                        size={size}
                        variant={variant}
                        href={href ? href : "#"}
                >{title}</Button>
            </Box>
        </div>
    )
}