import {Box, useMediaQuery} from "@mui/material";
import walkingForAlms from "../../../assets/images/home/introduction/walking-for-alms.png";
import {commonStyles} from "../../style/commonStyle";

export const Introduction = (props) => {
    const classes = commonStyles()
    const minWidth600 = useMediaQuery('(min-width:600px)');
    return (
        <div data-aos="fade-up" style={{
            margin: '2rem auto 3rem auto',
            display: "flow-root",
            width: "70%",
            padding: '1rem',
            borderRadius: "1rem",
        }}
        >

            <Box
                component="img"
                sx={{
                    float: 'right',
                    marginLeft: '5rem',
                    shapeMargin: '2rem',
                    shapeOutside: `url(${walkingForAlms})`,
                }}
                src={walkingForAlms}
            />
            <p id={"introduction"}
                style={{
                    fontFamily: 'FMSamantha',
                    lineHeight: 2.5,
                    fontSize: "1.2rem",
                    textAlign: 'justify',
                    color: "#024694",
                }}
            >
                §¾> rd;‍%Skays yqfol,d jkdka;rhkays iajNdúl mßirh weiqre fldgf.k ;u wdOHd;añl mdßY=oaêh fyj;a Ôú;fha h:d¾:h fiùu ms‚i W;aidyj;a jk h;sjrhdkka jykafia,d msßila yd Wka jykafia,d weiqre fldgf.k hy .=K jvk oeyeñ msßila jvk ,o" Ndú; lrk ,o ;u wdOHd;añl Yla;sh kid fkdf.k tuÕska wjYH;d we;s wkqkag o msysgla jkakg h;ak orkd ksy~ l;djl ishqï iajrh <span style={{lineHeight: 1, color: "#ff8300", fontSize: "3rem"}}>mdidÈl</span> kñka fufia m%lïmkh jk j.hs''''
            </p>
        </div>
    );
}