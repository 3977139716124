import {API_CONFIGS} from "../../config";
import {BFF_ENDPOINTS} from "../../utils/constants";
import {_get, _get_open} from "../fetch-api";

export const loadMonasteryById = (callback, errorCallback, id) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.loadMonasteryById}/${id}`;
  _get(callback, endpointURL, {}, null, errorCallback);
};

export const loadMonasteriesSuggestions = callback => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.loadMonasterySuggestions}`;
  _get_open(callback, endpointURL);
};
