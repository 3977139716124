import * as React from 'react';

import {useContext, useState} from "react";

import {
    Box, TextField, DialogContent, DialogContentText, DialogActions,
} from "@mui/material";

import {Login,} from "@mui/icons-material";

import {LoadingButton} from "@mui/lab";
import {login} from "../../../client/authentication/auth";
import {toast} from "react-toastify";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {AppContext} from "../../../context/appContext";

// create web link form
export default function LoginForm(props) {

    const [isLoading, setIsLoading] = useState(false);

    const [userName, setUserName] = useState('');

    const { authenticated } = useContext(AppContext)
    const handleChangeUserName = (event) => {
        setUserName(event.target.value);
    };
    const [userNameValidity, setUserNameValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validateUserName = () => {
        let validity = {};
        if (!userName || userName === '') {
            validity.isValid = false;
            validity.errorMessage = "Username cannot be empty"
            setUserNameValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setUserNameValidity(validity)
            return true
        }
    };

    const [password, setPassword] = useState('');
    const handleChangePassword = (event) => {
        setPassword(event.target.value);
    };
    const [passwordValidity, setPasswordValidity] = useState({
        isValid: true,
        errorMessage: ''
    })
    const validatePassword = () => {
        let validity = {};
        if (!password || password === '') {
            validity.isValid = false;
            validity.errorMessage = "Password cannot be empty"
            setPasswordValidity(validity)
            return false
        } else {
            validity.isValid = true;
            validity.errorMessage = ""
            setPasswordValidity(validity)
            return true
        }
    };

    const validateInputFields = () => {
        let formValidators = []
        let isFormValid = true
        if (!validateUserName()) {
            formValidators[0] = false
        }
        if (!validatePassword()) {
            formValidators[1] = false
        }

        formValidators.map((val) => {
            if (!val) {
                isFormValid = false
            }
        })
        return isFormValid;
    }

    const loginFunc = () => {
        if (!validateInputFields()) {
            return
        }
        setIsLoading(true)
        login(userName, password,
            data => {
                toast(`User login success!`,
                    {
                        closeOnClick: true,
                        type: "success",
                        theme: "light",
                        icon: <CheckCircleOutlineIcon/>
                    });
                authenticated(data)
                props.onLoginSuccess();
            },
            error => {
                setIsLoading(false)
                if (error.error_description) {
                    toast(`Error: ${error.error_description}`,
                        {
                            closeOnClick: true,
                            type: "error",
                            theme: "light",
                            icon: <ErrorOutlineIcon/>
                        })
                } else {
                    toast(`Error: ${error}`,
                        {
                            closeOnClick: true,
                            type: "error",
                            theme: "light",
                            icon: <ErrorOutlineIcon/>
                        });
                }
            });
    };

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': {m: 1, width: '50ch',},
                "& .MuiInputLabel-root": {fontFamily: 'Roboto'},
                "& .MuiInputBase-input": {fontFamily: 'Roboto'},
                "& .MuiFormHelperText-root": {fontFamily: 'Roboto'},
                "& .MuiButtonBase-root": {fontFamily: 'Roboto'},
            }}
            noValidate
            autoComplete="off"
        >
            <DialogContent dividers={true}>
                <DialogContentText
                    id="scroll-dialog-description"
                    tabIndex={-1}
                >
                </DialogContentText>
                <div>
                    <TextField
                        fullWidth
                        id="username-input"
                        label="Username"
                        type="text"
                        value={userName}
                        onChange={handleChangeUserName}
                        error={!userNameValidity.isValid}
                        onBlur={validateUserName}
                        helperText={(userNameValidity.isValid) ? "Enter username here" : userNameValidity.errorMessage}
                    />
                </div>
                <div>
                    <TextField
                        fullWidth
                        id="password-input"
                        label="Password"
                        type="password"
                        value={password}
                        onChange={handleChangePassword}
                        error={!passwordValidity.isValid}
                        onBlur={validatePassword}
                        helperText={passwordValidity.isValid ? "Enter password here" : passwordValidity.errorMessage}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    size="medium"
                    variant="outlined"
                    onClick={loginFunc}
                    loading={isLoading}
                    loadingPosition="end"
                    endIcon={<Login/>}
                >Login</LoadingButton>
            </DialogActions>
        </Box>
    )
}