import {API_CONFIGS} from "../../config";
import {BFF_ENDPOINTS} from "../../utils/constants";
import {_get, _get_open,} from "../fetch-api";

export const searchEvents = (
    callback,
    errorCallback,
    pageNumber,
    pageSize,
    status,
    searchParams
) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.searchEvents}`;
  const params = {
    pageNumber,
    pageSize,
    status,
    sortProperty: 'createdDate',
  };
  let requestParams = {...params, ...searchParams};
  _get(callback, endpointURL, requestParams, null, errorCallback);
};


export const getEventById = (callback, id, errorCallback) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.loadEventById}/${id}`;
  _get(callback, endpointURL, {}, null, errorCallback);
};

export const loadEventSuggestions = (callback,
                                     errorCallback,
                                     pageNumber,
                                     pageSize,
                                     status,
                                     searchParams
) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.loadEventSuggestions}`;
  const params = {
    pageNumber,
    pageSize,
    status,
    sortProperty: 'createdDate',
  };
  let requestParams = {...params, ...searchParams};
  _get_open(callback, endpointURL, requestParams, null, errorCallback);
};
