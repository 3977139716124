import { Box, ImageList, ImageListItem, Typography } from "@mui/material";
import { API_CONFIGS } from "../../../config";

export const CommonImagesContainer = (props) => {

    const { images, caption, boxShadow, position, columns, gap, backgroundColor, width, borderRadius, border } = props.data

    return (
        <div data-aos={props.transition}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: position,
                    alignItems: position,
                    backgroundColor: backgroundColor,
                    margin: 'auto',
                    padding: 'auto',
                    width: width
                }}>
                <ImageList variant="masonry" cols={columns ? columns : 1} gap={gap ? gap : 10}>
                    {images.map((item, index) => (
                        <ImageListItem key={index}>
                            <img
                                style={{
                                    boxShadow: boxShadow,
                                    shapeOutside: `${API_CONFIGS.fileBaseUrl}${item}`,
                                    border: border,
                                    borderRadius: borderRadius,
                                    margin: 'auto',
                                }}
                                src={`${API_CONFIGS.fileBaseUrl}${item}`}
                                srcSet={`${API_CONFIGS.fileBaseUrl}${item}`}
                                alt={`${API_CONFIGS.fileBaseUrl}${item}`}
                                loading="lazy"
                            />
                        </ImageListItem>
                    ))}
                </ImageList>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: position,
                    alignItems: position,
                }}
            >
                <Typography sx={{
                    fontSize: '1.2rem',
                    fontStyle: 'italic',
                }}
                    color={"grey"}
                    fontFamily={"FMBindumathi"}
                >
                    {caption.text}
                </Typography>
            </Box>
        </div>
    )
}