import React, {createContext, useEffect, useState} from 'react'
import {getAccessToken, getAnAccessToken} from "../utils/token-store";
import {parseJwt} from "../utils/app-utils";
export const AppContext = createContext()
export const AppContextProvider = (props) => {
    const [auth, setAuth] = useState(getAccessToken()? parseJwt(getAccessToken()): {})
    const [monasterySuggestions, setMonasterySuggestions] = useState([])
    const [foundationSuggestions, setFoundationSuggestions] = useState([])
    const authenticated = (data) => {
        setAuth(data)
    }

    const updateMonasterySuggestions = (data) => {
        setMonasterySuggestions(data)
    }
    const updateFoundationSuggestions = (data) => {
        setFoundationSuggestions(data)
    }
    return (
        <AppContext.Provider
            value={{
                auth,
                authenticated,
                monasterySuggestions,
                foundationSuggestions,
                updateMonasterySuggestions,
                updateFoundationSuggestions,
            }}>
            {props.children}
        </AppContext.Provider>
    )
}