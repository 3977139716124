import {Box, Button} from "@mui/material";
import {ArrowDownward} from "@mui/icons-material";
import ScrollToTopButton from "./footer/ScrollToTopButton";
import React from "react";

export default function WallPaper(props) {
    const {} = props;

    return (
        <Box
             style={{
                 backgroundImage: `url("/home/silhouetted-leaves-gracefully-cast-translucent-shadows-neutral-backdrop-kissed-by-morning-sunlight.jpg")`,
                 height: "100vh",
                 backgroundPosition: "center",
                 backgroundRepeat: "no-repeat",
                 backgroundSize: "cover",
                 position: "relative",
                 display: "flex",
                 justifyContent: "center",
                 alignItems: "center",
                 maxWidth:"100%"
             }}
        >
            <Box
                sx={{
                    position: "absolute",
                    margin: "auto",
                    width: '60%',
                    height: '20%',
                    borderRadius: '1rem',
                    fontFamily: 'FMBasuru',
                    textAlign: "center",
                    alignItems: "center",
                }}
            >
                <p data-aos="fade-up"
                    style={{
                        fontSize: '2rem', fontFamily: "FMGangani",
                        color: '#164919',
                        textAlign: "center",
                        margin: "auto",
                    }}
                >
                    {`yd;ami isidrd msßuÈk m%{dj hï m%idohla ika;dkh ;=< cks; lr;a o th `}
                    <span data-aos="fade-down" style={{
                        fontFamily: "FMSamantha",
                        fontSize: "4rem",
                        color: "#d73a06"
                    }}>
                        mdidÈl
                    </span> {`kï fõ'`}
                </p>
                <ScrollToTopButton showBelow={250} />
            </Box>
            <Button variant={"outlined"} size={'large'}
                    onClick={() => document.getElementById("introduction").scrollIntoView({
                        behavior: 'smooth',
                        inline: 'center'
                    })}
                    style={{
                        border: "3px solid" +
                            "#f5c991",
                        position: "absolute",
                        margin: "60vh auto auto auto",
                        width: '100px'
                    }}
            >
                <ArrowDownward style={{color: "#f5c991"}}/>
            </Button>
        </Box>
    );
};