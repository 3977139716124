import * as React from "react";

import {useMemo, forwardRef, useState} from "react";

import PropTypes from 'prop-types';

import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import ListItemIcon from '@mui/material/ListItemIcon';

import {Link as RouterLink, MemoryRouter} from 'react-router-dom';
import {StaticRouter} from 'react-router-dom/server';
import {ArrowDropDown, ArrowRight} from "@mui/icons-material";
import {Collapse} from "@mui/material";


const DrawerNavigationItems = (props) => {

    const {
        wildMonasteries,
        monasteries,
        hospitalityMonasteries,
        shasanikaServicesFoundations,
        socialServicesFoundations
    } = props

    function Router(props) {
        const {children} = props;
        if (typeof window === 'undefined') {
            return <StaticRouter location="/deshana">{children}</StaticRouter>;
        }

        return (
            <MemoryRouter initialEntries={['/deshana']} initialIndex={0}>
                {children}
            </MemoryRouter>
        );
    }

    Router.propTypes = {
        children: PropTypes.node,
    };

    function ListItemLink(props) {
        const {icon, primary, to, level} = props;

        const renderLink = useMemo(
            () =>
                forwardRef(function Link(itemProps, ref) {
                    return <RouterLink to={to} ref={ref} {...itemProps} role={undefined}/>;
                }),
            [to],
        );

        return (
            <li>
                <ListItemButton
                    selected={false}
                    onClick={(event) => {}}
                    component={renderLink}
                    sx={{pl: level * 2}}
                >
                    {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
                    <ListItemText  primary={primary}/>
                </ListItemButton>
            </li>
        );
    }

    ListItemLink.propTypes = {
        icon: PropTypes.element,
        primary: PropTypes.string.isRequired,
        to: PropTypes.string.isRequired,
    };

    const [isCollapseMonasteryMain, setIsCollapseMonasteryMain] = useState(false);
    const [isCollapseMonastery, setIsCollapseMonastery] = useState(false);

    const handleCollapseMonasteryMain = () => {
        isCollapseMonasteryMain ? setIsCollapseMonasteryMain(false) : setIsCollapseMonasteryMain(true);
    }
    
    const handleCollapseMonastery = () => {
        isCollapseMonastery ? setIsCollapseMonastery(false) : setIsCollapseMonastery(true);
    }

    const [isCollapseWildMonastery, setIsCollapseWildMonastery] = useState(false);
    const handleCollapseWildMonastery = () => {
        isCollapseWildMonastery ? setIsCollapseWildMonastery(false) : setIsCollapseWildMonastery(true);
    }

    const [isCollapseHosMonastery, setIsCollapseHosMonastery] = useState(false);
    const handleCollapseHosMonastery = () => {
        isCollapseHosMonastery ? setIsCollapseHosMonastery(false) : setIsCollapseHosMonastery(true);
    }

    const [isCollapseFoundationMain, setIsCollapseFoundationMain] = useState(false);
    const handleCollapseFoundationMain = () => {
        isCollapseFoundationMain ? setIsCollapseFoundationMain(false) : setIsCollapseFoundationMain(true);
    }

    const [isCollapseSoFoundation, setIsCollapseSoFoundation] = useState(false);
    const handleCollapseSoFoundation = () => {
        isCollapseSoFoundation ? setIsCollapseSoFoundation(false) : setIsCollapseSoFoundation(true);
    }

    const [isCollapseShaFoundation, setIsCollapseShaFoundation] = useState(false);
    const handleCollapseShaFoundation = () => {
        isCollapseShaFoundation ? setIsCollapseShaFoundation(false) : setIsCollapseShaFoundation(true);
    }

    return (
        <List >
            <ListItemButton
                onClick={handleCollapseMonasteryMain}
            >
                <ListItemText  primary={"mdidÈl fikiqka"}/>
                {isCollapseMonasteryMain ? <ArrowDropDown/> : <ArrowRight/>}
            </ListItemButton>
            <Collapse sx={{ml: 4}} in={isCollapseMonasteryMain} timeout="auto" unmountOnExit>
                <List>
                    <ListItemButton
                        onClick={handleCollapseMonastery}
                    >
                        <ListItemText  primary={"mdidÈl fikiqka"}/>
                        {isCollapseMonastery ? <ArrowDropDown/> : <ArrowRight/>}
                    </ListItemButton>
                    <Collapse in={isCollapseMonastery} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {monasteries && monasteries.map((mon, index) => (
                                <ListItemLink key={index} level={3} to={`/monasteries/${mon.id}`} primary={mon.name} />
                            ))}
                        </List>
                    </Collapse>
                    <ListItemButton
                        onClick={handleCollapseWildMonastery}
                    >
                        <ListItemText  primary={"mdidÈl jk fikiqka"}/>
                        {isCollapseWildMonastery ? <ArrowDropDown/> : <ArrowRight/>}
                    </ListItemButton>
                    <Collapse in={isCollapseWildMonastery} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {wildMonasteries && wildMonasteries.map((mon, index) => (
                                <ListItemLink key={index} level={3} to={`/monasteries/${mon.id}`} primary={mon.name} />
                            ))}
                        </List>
                    </Collapse>
                    <ListItemButton
                        onClick={handleCollapseHosMonastery}
                    >
                        <ListItemText  primary={"mdidÈl i;aldrl fikiqka"}/>
                        {isCollapseHosMonastery ? <ArrowDropDown/> : <ArrowRight/>}
                    </ListItemButton>
                    <Collapse in={isCollapseHosMonastery} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {hospitalityMonasteries && hospitalityMonasteries.map((mon,index) => (
                                <ListItemLink key={index} level={3} to={`/monasteries/${mon.id}`} primary={mon.name} />
                            ))}
                        </List>
                    </Collapse>
                </List>
            </Collapse>
            <ListItemLink to='/deshana' primary="mdidÈl O¾u foaYKd"/>
            <ListItemLink to='/events' primary="jHdmD;s iy jevigyka"/>
            <ListItemButton
                onClick={handleCollapseFoundationMain}
            >
                <ListItemText  primary={"mdidÈl mokï"}/>
                {isCollapseFoundationMain ? <ArrowDropDown/> : <ArrowRight/>}
            </ListItemButton>
            <Collapse sx={{ml: 4}} in={isCollapseFoundationMain} timeout="auto" unmountOnExit>
                <List>
                    <ListItemButton
                        onClick={handleCollapseShaFoundation}
                    >
                        <ListItemText  primary={"Ydiksl fiajd mokï"}/>
                        {isCollapseShaFoundation ? <ArrowDropDown/> : <ArrowRight/>}
                    </ListItemButton>
                    <Collapse in={isCollapseShaFoundation} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {shasanikaServicesFoundations && shasanikaServicesFoundations.map((mon,index) => (
                                <ListItemLink key={index} level={3} to={`/foundations/${mon.id}`} primary={mon.name} />
                            ))}
                        </List>
                    </Collapse>
                    <ListItemButton
                        onClick={handleCollapseSoFoundation}
                    >
                        <ListItemText  primary={"iudc i;aldrl mokï"}/>
                        {isCollapseSoFoundation ? <ArrowDropDown/> : <ArrowRight/>}
                    </ListItemButton>
                    <Collapse in={isCollapseSoFoundation} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {socialServicesFoundations && socialServicesFoundations.map((mon,index) => (
                                <ListItemLink key={index} level={3} to={`/foundations/${mon.id}`} primary={mon.name} />
                            ))}
                        </List>
                    </Collapse>
                </List>
            </Collapse>
            <ListItemLink to='/about' primary="úuiSï"/>
        </List>
    )
}

export default DrawerNavigationItems;