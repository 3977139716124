import {API_CONFIGS} from "../../config";
import {BFF_ENDPOINTS} from "../../utils/constants";
import {_get, _get_open} from "../fetch-api";

export const searchFoundations = (
    callback,
    errorCallback,
    pageNumber,
    pageSize,
    status,
    keyword
) => {
    const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.searchFoundations}`;
    const params = {
        pageNumber,
        pageSize,
        status,
        keyword,
        sortProperty: 'name',
    };
    _get(callback, endpointURL, params, null, errorCallback);
};

export const loadFoundationById = (callback, errorCallback, id) => {
    const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.loadFoundationById}/${id}`;
    _get(callback, endpointURL, {}, null, errorCallback);
};

export const loadFoundationsSuggestions = callback => {
    const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.loadFoundationsSuggestions}`;
    _get_open(callback, endpointURL);
};
