import {commonStyles} from "../../style/commonStyle";
import {Box} from "@mui/material";

export const Cta = (props) => {
    const classes = commonStyles()
    return (
        <Box
            sx={{background: `url(${props.image}) fixed center`,}}
            className={classes.cta}
        >
            {props.content}
        </Box>
    )
}