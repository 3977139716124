import {Box,} from "@mui/material";


export const Contact = (props) => {

    return (
        <Box>

        </Box>
    )
}