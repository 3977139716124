import {Box, Typography} from "@mui/material";


export const CommonContainer = (props) => {

    const containerStyle = {
        "& > *": {
            fontFamily: "FMDerana"
        },
        fontFamily: 'FMBasuru',
        marginTop: '1rem',
        marginLeft: '4vw',
        marginBottom: '4vh',
        marginRight: '4vw',
        padding: '1rem',
        borderRadius: 1,
        boxShadow: props.isShadowEnabled ? `0px 2px 10px rgba(0, 62, 200, 0.5)` : '',
        backgroundImage: !props.isBackgroundGradientEnabled ? `` :  `conic-gradient(from 50deg at 50% 50%,
                    rgba(242, 245, 246, 0.08),
                    rgba(255, 97, 0, 0.08),
                    rgba(0, 255, 55, 0.08),
                    rgba(139, 150, 254, 0.08),
                    rgba(246, 47, 214, 0.08),
                    rgba(255, 133, 0, 0.08),
                    rgba(1, 246, 216, 0.08),
                    rgba(152, 250, 0, 0.08),
                    rgba(154, 0, 252, 0.08))`
    }

    return (
        <Box data-aos={props.transitionEnabled ? "fade-up" : ""}
             sx={{
                 justifyContent: "center",
                 alignItems: "center",
                 display: "flex",
        }}
        >
            <Box sx={{m: 2}}
            >
                <Typography variant={"h5"} sx={{fontFamily: 'FMDerana'}}>{props.header}</Typography>
            </Box>
            {props.children}
        </Box>
    );
}