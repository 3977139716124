import * as React from 'react';
import { Dialog, DialogTitle } from '@mui/material';
import {makeStyles} from "@mui/styles";


export default function MainModalDialog(props) {

    const handleClose = () => {
        props.handleClose(false);
    };

    const classes = makeStyles({
        root: {borderRadius: '10px'}
    })

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={handleClose}
                fullWidth
                maxWidth={props.size?props.size:'sm'}
                scroll='paper'
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                PaperProps={{
                    style: { borderRadius: 15 }
                }}
            >
                <DialogTitle id="scroll-dialog-title">{props.header}</DialogTitle>
                {props.body}
            </Dialog>
        </div>
    );
}