import {Box, List, ListItem, ListItemIcon, Typography} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { API_CONFIGS } from "../../../config";

export const CommonTextListContainer = (props) => {

    const {items, fontSize, color, font, position, wrapImage} = props.data
    return (
        <div data-aos={props.transition}>
            <Box
            >
                {wrapImage && (
                    <img
                        style={{
                            float: wrapImage.position,
                            marginLeft: (wrapImage.position==='right')? "5rem": "",
                            marginRight: (wrapImage.position==='left')? "5rem": "",
                            shapeMargin: '1rem',
                            width: wrapImage.size,
                            height: wrapImage.size,
                            shapeOutside: `url(${API_CONFIGS.fileBaseUrl}${wrapImage.image})`,
                        }}
                        src={`${API_CONFIGS.fileBaseUrl}${wrapImage.image}`}
                    />
                )}
                <List
                    sx={{
                        padding: "1rem",
                        margin: "0",
                        color: color,
                        fontSize: fontSize,
                        position: position,
                        '& .MuiListItemText-primary': {fontFamily: 'FMDerana',},
                    }}>
                    {items.map((item, lstIndex) => (
                        <Box key={lstIndex}>
                            <Box sx={{
                                display: {
                                    xs: 'none',
                                    md: 'flex',
                                }
                            }}>
                                <ListItem>
                                    <ListItemIcon>
                                        <CheckCircleOutlineIcon/>
                                    </ListItemIcon>
                                    <Typography
                                        sx={{
                                            fontSize: fontSize,
                                        }}
                                        color={color}
                                        fontFamily={font}
                                        position={"relative"}
                                        float={position}
                                    >
                                        {item}
                                    </Typography>
                                </ListItem>
                            </Box>
                        </Box>
                    ))}
                </List>
            </Box>
        </div>
    )
}