import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import Box from '@mui/material/Box';

import {Route, Routes} from "react-router-dom";
import Monasteries from "../../pages/monasteries";
import Foundations from "../../pages/foundations";
import Deshana from "../../pages/deshana";
import DeshanaWebLink from '../../pages/deshana/webLink';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import MainAppBar from "../appbar/appBar";
import MainDrawer from "../drawer/drawer";
import Events from "../../pages/events";
import {Event} from "../../pages/events/event";
import Home from "../../pages/home";
import {Contact} from "../../pages/contact/contact";
import {loadMonasteriesSuggestions} from "../../../client/monastery/call";
import {loadFoundationsSuggestions} from "../../../client/foundation/call";
import {AppContext} from "../../../context/appContext";

const drawerWidth = 240;

export default function MainLayout() {

    const {updateMonasterySuggestions, updateFoundationSuggestions} = useContext(AppContext)

    const [temp, setTemp] = useState(null);
    //-------------------
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    //------------------

    const handleLogout = () => {
        setTemp("temp")
    }

    useEffect(() => {
        loadMonSuggestions()
        loadFoundSuggestions()
    }, [])
    const loadMonSuggestions = () => {
        loadMonasteriesSuggestions(result => {
            updateMonasterySuggestions(result.content)
        });
    }

    const loadFoundSuggestions = () => {
        loadFoundationsSuggestions(result => {
            updateFoundationSuggestions(result.content)
        });
    }

    return (
        <Box sx={{display: 'flex'}}>
            <ToastContainer/>
            <MainAppBar
                handleDrawerToggle={handleDrawerToggle}
                handleLogout={handleLogout}
            />
            <Box component = "nav"   sx={{maxWidth:'100%',margin:"auto"}}>
                <MainDrawer
                    handleDrawerToggle={handleDrawerToggle}
                    drawerWidth={drawerWidth}
                    open={mobileOpen}
                   />
            </Box>

            <Box sx={{flexGrow: 1, maxWidth: '100%', margin: 'auto', overflowX: 'hidden'}}>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/home" element={<Home/>}/>
                    <Route path="/deshana" element={<Deshana/>}/>
                    <Route path="/deshana/:parentId" element={<Deshana/>}/>
                    <Route path="/web-link" element={<DeshanaWebLink/>}/>
                    <Route path="/monasteries/:id" element={<Monasteries/>}/>
                    <Route path="/foundations/:id" element={<Foundations/>}/>
                    <Route path="/events" element={<Events/>}/>
                    <Route path="/events/:id" element={<Event/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                </Routes>
            </Box>
        </Box>
    );
}