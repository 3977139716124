import {
    Box,
    Card,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import {
    CleaningServices,
    Eject,
    Search,
} from "@mui/icons-material";
import {useEffect, useState} from "react";
import {LoadingButton} from "@mui/lab";
import {loadMonasteriesSuggestions} from "../../../client/monastery/call";
import {loadFoundationsSuggestions} from "../../../client/foundation/call";
import * as moment from "moment";
import {makeStyles} from "@mui/styles";


export default function EventSearchForm(props) {

    const [eventDateFrom, setEventDateFrom] = useState(null);
    const [eventDateTo, setEventDateTo] = useState(null);
    const [monastery, setMonastery] = useState({});
    const [foundation, setFoundation] = useState({});

    const [monasterySuggestions, setMonasterySuggestions] = useState(['']);
    const [foundationSuggestions, setFoundationSuggestions] = useState(['']);

    useEffect(() => {
        loadMonasterySuggestionsFunc()
        loadFoundationSuggestionsFunc()
    }, [])

    const handleChangeEventDateFrom = (value) => {
        let val = null
        if (value) {
            val = value.$d
            setEventDateFrom(moment(val).format('YYYY-MM-DD'));
        } else {
            setEventDateFrom(null)
        }
    };
    const handleChangeEventDateTo = (value) => {
        let val = null
        if (value) {
            val = value.$d
            setEventDateTo(moment(val).format('YYYY-MM-DD'));
        } else {
            setEventDateTo(null)
        }
    };

    const handleChangeMonastery = (event) => {
        setMonastery(monasterySuggestions.find((mnstry) => mnstry.id === event.target.value));
    };
    const handleChangeFoundation = (event) => {
        setFoundation(foundationSuggestions.find((fnd) => fnd.id === event.target.value));
    };

    const handleSearch = () => {
        let searchParams = {
            eventDateFrom: eventDateFrom,
            eventDateTo: eventDateTo,
            monasteryId: monastery ? monastery.id : "",
            foundationId: foundation ? foundation.id : "",
        }
        props.searchPayments(searchParams)
    }

    function loadMonasterySuggestionsFunc() {
        loadMonasteriesSuggestions(result => {
            setMonasterySuggestions(result.content);
        });
    }

    function loadFoundationSuggestionsFunc() {
        loadFoundationsSuggestions(result => {
            setFoundationSuggestions(result.content);
        });
    }

    const setInputsToDefaultOnFormSubmit = () => {
        setEventDateFrom(null)
        setEventDateTo(null)
        setMonastery({})
        setFoundation({})
    }

    const classes = makeStyles({
        root: {
            "& > *": {fontFamily: 'Roboto'}
        }
    });

    return (
        <Card sx={{marginBottom: 1, border: '1px solid', borderColor: 'rgb(229,226,226)'}}>
            <Box component="form"
                 sx={{
                     '& .MuiTextField-root': {m: 1, width: '32ch'},
                 }}
                 noValidate
                 autoComplete="off">
                <Typography mt={2} align="center" variant="subtitle1" gutterBottom
                            component="div">
                    fidhkak
                </Typography>
                <div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            type='date'
                            id="transaction-date-from-input"
                            label="wdrïNl Èkh "
                            inputFormat="YYYY-MM-DD"
                            value={eventDateFrom}
                            onChange={handleChangeEventDateFrom}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    sx={{fontFamily: 'Roboto'}}
                                    helperText={"wdrïNl Èkh we;=,;a lrkak'''"}
                                />
                            }

                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            type='date'
                            id="transaction-date-to-input"
                            label="wjika l, Èkh "
                            inputFormat="YYYY-MM-DD"
                            value={eventDateTo}
                            onChange={handleChangeEventDateTo}
                            className={classes.root}
                            renderInput={(params) =>
                                <TextField
                                    sx={{fontFamily: 'Roboto'}}
                                    {...params}
                                    className={classes.root}
                                    helperText={"wjika l, Èkh we;=,;a lrkak'''"}
                                />
                            }

                        />
                    </LocalizationProvider>
                    <FormControl sx={{m: 1, minWidth: '32ch'}}>
                        <InputLabel id="monastery-label">ia:dkh</InputLabel>
                        <Select
                            labelId="monastery-label"
                            id="monastery-input"
                            defaultValue=""
                            value={monastery ? monastery.id ? monastery.id : '' : ""}
                            onChange={handleChangeMonastery}
                            autoWidth
                            label="ia:dkh"
                        >
                            <MenuItem key={"none"} value="">
                                <em>None</em>
                            </MenuItem>
                            {(monasterySuggestions) && monasterySuggestions.map((monastery, index) => (
                                <MenuItem key={index} value={monastery.id}>{monastery.nameUnicode}</MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{"ia:dkh we;=,;a lrkak'''"}</FormHelperText>
                    </FormControl>
                </div>
                <div>
                    <FormControl sx={{m: 1, minWidth: '32ch'}}>
                        <InputLabel id="foundation-label">moku </InputLabel>
                        <Select
                            labelId="foundation-label"
                            id="foundation-input"
                            defaultValue=""
                            value={foundation ? foundation.id ? foundation.id : '' : ""}
                            onChange={handleChangeFoundation}
                            autoWidth
                            label="moku "
                        >
                            <MenuItem key={"none"} value="">
                                <em>None</em>
                            </MenuItem>
                            {(foundationSuggestions) && foundationSuggestions.map((foundation, index) => (
                                <MenuItem key={index} value={foundation.id}>{foundation.nameUnicode}</MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{"moku we;=,;a lrkak'''"}</FormHelperText>
                    </FormControl>
                </div>
            </Box>
            <Box mb={1}>
                <LoadingButton sx={{marginRight: 1, fontFamily: 'Roboto'}}
                               size="medium"
                               variant="outlined"
                               onClick={handleSearch}
                               loading={false}
                               loadingPosition="end"
                               endIcon={<Search/>}
                >Search</LoadingButton>
                <LoadingButton sx={{marginRight: 1, fontFamily: 'Roboto'}}
                               size="medium"
                               variant="outlined"
                               onClick={() => {
                                   setInputsToDefaultOnFormSubmit()
                                   props.searchPayments({})
                                   props.closeForm()
                               }}
                               loading={false}
                               loadingPosition="end"
                               endIcon={<CleaningServices/>}
                >Clear Filters</LoadingButton>
                <LoadingButton
                    sx={{fontFamily: 'Roboto'}}
                    size="medium"
                    variant="outlined"
                    onClick={props.closeForm}
                    loading={false}
                    loadingPosition="end"
                    endIcon={<Eject/>}
                >Hide Search</LoadingButton>
            </Box>
        </Card>
    )
}