export const themeOptions = {
    palette: {
        type: 'light',
        primary: {
            main: '#004d40',
        },
        secondary: {
            main: '#c2185b',
        },
        background: {
            default: '#f5f5f5',
            paper: '#fafafa',
        },
    },
    typography: {
        h1:{
            fontFamily: 'FMBasuru',
        },
        h2:{
            fontFamily: 'FMBasuru',
        },
        h3:{
            fontFamily: 'FMBasuru',
        },
        h4:{
            fontFamily: 'FMBasuru',
        },
        h5:{
            fontFamily: 'FMBasuru',
        },
        h6:{
            fontFamily: 'FMBasuru',
        },
        subtitle1:{
            fontFamily: 'FMBasuru',
        },
        subtitle2:{
            fontFamily: 'FMBasuru',
        },
        body1:{
            fontFamily: 'FMMalithi',//p tag
        },
        body2:{
            fontFamily: 'FMMalithi',//p tag
        },
        inherit:{
            fontFamily: 'FMMalithi',//p tag
        },


        // allVariants: {
        // //     //fontFamily: 'Ubuntu , \'sans-serif\'',
        //    fontFamily: 'FMMalithi', },

    },
    shape: {
        borderRadius: 10,
    },
    spacing: 8,
    props: {
        MuiTooltip: {
            arrow: true,
        },
        MuiAppBar: {
            color: 'inherit',
        },
    },
    overrides: {
        MuiAppBar: {
            colorInherit: {
                backgroundColor: '#689f38',
                color: '#fff',
            },
        },
    },
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    borderRadius: 25,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    borderRadius: 25,
                    textTransform: 'math-auto'
                },
            },
        },
        MuiCalendarPicker: {
            styleOverrides: {
                root: {
                    textTransform: 'math-auto',
                    fontFamily: 'Roboto'
                },
                label: {
                    fontFamily: 'Roboto'
                }
            },
        },
        MuiPickersCalendarHeader: {
            styleOverrides: {
                root: {
                    textTransform: 'math-auto',
                    fontFamily: 'Roboto'
                },
                label: {
                    fontFamily: 'Roboto'
                }
            },
        },
        MuiPickersDay: {
            styleOverrides: {
                root: {
                    textTransform: 'math-auto',
                    fontFamily: 'Roboto'
                },
                label: {
                    fontFamily: 'Roboto'
                }
            },
        },
    }

};