import WallPaper from "./wallPaper";
import MainSection from "./mainSection";
import { PageLayout } from "../../ui/layout/pageLayout";

export default function Home() {
    return (
        <PageLayout sx={{}}>
            <WallPaper/>
            <MainSection/>
        </PageLayout>
    );
}