import {Box} from "@mui/material";

export default function CommonGathaContainer(props) {
    return (
        <Box data-aos="fade-up"
             sx={{
                 maxWidth:'100%',
                 alignItems:'center',
                 margin: '2rem',
                 padding: '2rem',
                 textAlign: "center",
             }}
        >
            {props.children}
        </Box>
    )
}