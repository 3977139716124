import {API_CONFIGS} from '../config';
import {getAccessToken, getAnAccessToken} from './token-store';

const defaultInterceptor = req => {
    var token = getAccessToken();

    if (token) {
        req.headers['Authorization'] = 'Bearer ' + token;
    }

    req.headers['Expires'] = '-1';
    req.headers['Cache-Control'] =
        'no-cache,no-store,must-revalidate,max-age=-1,private';

    return req;
};

const anInterceptor = req => {
    var token = getAnAccessToken();

    if (token) {
        req.headers['Authorization'] = 'Bearer ' + token;
    }

    req.headers['Expires'] = '-1';
    req.headers['Cache-Control'] =
        'no-cache,no-store,must-revalidate,max-age=-1,private';

    return req;
};

const openInterceptor = req => {
    req.headers['Expires'] = '-1';
    req.headers['Cache-Control'] =
        'no-cache,no-store,must-revalidate,max-age=-1,private';

    return req;
};

const loginInterceptor = req => {
    const basicToken = window.btoa(
        `${API_CONFIGS.clientId}:${API_CONFIGS.clientSecret}`
    );
    req.headers['Authorization'] = 'Basic ' + basicToken;
    req.headers['Expires'] = '-1';
    req.headers['Cache-Control'] =
        'no-cache,no-store,must-revalidate,max-age=-1,private';

    return req;
};

export {defaultInterceptor, loginInterceptor, openInterceptor, anInterceptor};
