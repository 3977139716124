import * as React from "react";
import {Box} from "@mui/material";

function getRandomColor() {
    return "hsl(" + Math.random() * 360 + ", 60%, 90%)";
}

export const PageLayout = (props) => {


    const [backgroundColor] = React.useState(getRandomColor())

    return (
        <Box sx={{
            margin: 0,maxWidth: '100%', backgroundColor: 'rgb(255,255,255)'
        }}>
            <Box sx={{
                margin: 0,
                width: '65%',
                overflow: 'hidden',
                backgroundColor: backgroundColor,
                align: 'center',
                padding: '20px',
                alignJustify: 'center',
                textAlign: 'center',
                justifyContent: "center",
                // margin: 'auto',
                borderBottomRightRadius: '20px',
                borderBottomLeftRadius: '20px',
            }}>
            </Box>

            <Box sx={{maxWidth: '100%', margin: 'auto'}}>
                {props.children}
            </Box>
        </Box>
    )
}
