import {Divider, Typography} from "@mui/material";
import {commonStyles} from "../../style/commonStyle";
import ctaImage from "../../../assets/images/home/cta/wild-monastery-nature.jpg"
import karapitiyaNightView from "../../../assets/images/home/cta/karapity-day-view.jpg"
import CommonGathaContainer from "../../ui/container/commonGathaContainer";
import {Introduction} from "./introduction";
import {Cta} from "../../ui/cta/cta";
import {WildMonasteryCta} from "./wildMonasteryCta";
import {Contacts} from "./contacts";
import {CardContainer} from "../../ui/container/cardContainer";
import {CommonCard} from "../../ui/card/commonCard";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../context/appContext";
import {ExtendedIntroduction} from "./extendedIntroduction";
import {KarapitiyaNightViewCta} from "./karapitiyaNightViewCta";
import {MainSectionEvents} from "../events/mainSectionEvents";

export default function MainSection() {

    const {monasterySuggestions, foundationSuggestions} = useContext(AppContext)

    const [wildMonasteries, setWildMonasteries] = useState([])
    const [monasteries, setMonasteries] = useState([])
    const [hospitalityMonasteries, setHospitalityMonasteries] = useState([])

    useEffect(() => {
        let wildMon = [];
        let mon = [];
        let hosMon = [];
        monasterySuggestions.forEach(monastery => {
            if (monastery.type === "WMO") {
                wildMon.push(monastery);
            } else if (monastery.type === "MON") {
                mon.push(monastery);
            } else if (monastery.type === "HOM") {
                hosMon.push(monastery);
            }
        });
        setWildMonasteries(wildMon)
        setMonasteries(mon)
        setHospitalityMonasteries(hosMon)
    }, [
        monasterySuggestions
    ])

    const classes = commonStyles()
    return (
        <>
            <CommonGathaContainer>
                <Typography
                    className={classes.commonGathaText}
                    sx={{
                        fontFamily: 'FMEmanee',
                        fontSize: '2rem',
                        color: 'primary.main',
                    }}>
                    wmmld f; ukqfiiiq fh ckd mdr.dñfkd - w:dhx b;rd mcd ;Srfïjd kqOdj;s
                </Typography>
                <p
                    style={{
                        fontFamily: 'FMBasuru',
                        fontSize: '1.3rem',
                        color: '#8a0e15',
                }}>
                    ^iir ihqf¾& mrf;rg hkafkda ñksiqka w;frys ÿ¾,N fj;s ^iaj,am fofkls&" wkH jQ jeä fokd fuf;r wkqju ÿj;a
                </p>
            </CommonGathaContainer>
            <Introduction/>
            <ExtendedIntroduction/>
            <Cta
                image={ctaImage}
                content={<WildMonasteryCta/>}
            />
            <CardContainer header={"mdidÈl jk fikiqka"} headerColor={"#2c5e03"} headerFont={"Apex-13"}>
                <p data-aos="fade-in"
                   style={{
                       margin: '0',
                       fontFamily: "FMSamantha",
                       color: '#f57b00',
                       fontSize: '1.2rem',
                       lineHeight: '1.9rem',
                   }}
                >
                    {`O¾uh mqyqKqfjys h:d¾:h wkqj" jkdka;r iy l÷ wm úiska úYaj úoHd, f,i y÷kajk fohg iudk hehs mejiSu w;sfYdala;shla fkdfõ' jkdka;r hkq" fyd¢ka wid oek .;a O¾uh ;u Ôú;h ;=,ska oel .;a NslaIQka jykafia,dg muKla ;:d.;hka jykafiaf.a tu b.ekaùu ;u yoj; ;=,skau idlaId;a lr .ekSug h;ak ork ia:dkhls'`}
                </p>
                {wildMonasteries != null && (wildMonasteries.map(monastery => (
                    <div key={monastery.id} className="col-lg-4 col-md-6 d-flex align-items-stretch"
                         data-aos="fade-up">
                        <CommonCard
                            title={monastery.name}
                            thumbnail={monastery.thumbnail}
                            path={`/monasteries/${monastery.id}`}
                        />
                    </div>
                )))}
            </CardContainer>
            <CardContainer header={"mdidÈl i;aldrl fikiqka"} headerColor={"#014372"} headerFont={"Apex-13"}>
                <Typography>

                </Typography>
                {hospitalityMonasteries != null && (hospitalityMonasteries.map(monastery => (
                    <div key={monastery.id} className="col-lg-4 col-md-6 d-flex align-items-stretch"
                         data-aos="fade-up">
                        <CommonCard
                            title={monastery.name}
                            thumbnail={monastery.thumbnail}
                            path={`/monasteries/${monastery.id}`}
                        />
                    </div>
                )))}
            </CardContainer>
            <CardContainer header={"mdidÈl fikiqka"} headerColor={"#000000"} headerFont={"Apex-13"}>
                <Typography>

                </Typography>
                {monasteries != null && (monasteries.map(monastery => (
                    <div key={monastery.id} className="col-lg-4 col-md-6 d-flex align-items-stretch"
                         data-aos="fade-up">
                        <CommonCard
                            title={monastery.name}
                            thumbnail={monastery.thumbnail}
                            path={`/monasteries/${monastery.id}`}
                        />
                    </div>
                )))}
            </CardContainer>
            <Cta
                image={karapitiyaNightView}
                content={<KarapitiyaNightViewCta/>}
            />
            <CardContainer header={"mdidÈl mokï"} headerFont={"SaraBaron"}>
                <b>
                    <p data-aos="fade-in"
                        style={{
                            margin: '0',
                            fontFamily: "FMMalithi",
                            color: '#464646',
                            fontSize: '1.2rem',
                            lineHeight: '1.9rem',
                        }}
                    >
                        {`mdidÈl fikiqka oduh yd tla frdla ù isák oyia ixLHd; i;amqreIkaf.a tlS mskanr woyia ;:d.; iïud iïnqÿ mshdKka jykafiaf.a O¾uh yd úkhg wkql+,j msßisÿ fÉ;kdfjka hqla; jQ meyeÈ,s oelaulska hq;= j wêIaGdkh" ù¾hh" lemùu yd kqjKe;a;kaf.a .erySug ,la fkdjk wldrfhka l%shdjg kexùu ;=,ska wmf.a wjOdkh fhduq úh hq;= yd i;H jYfhka u msysg ms<sirK wjYH jk fkdfhla me;slvhka wdjrKh lsÍu i|yd fuu ˜‍mdidÈl mokï˜‍ wdrïN lrk ,§'`}
                    </p>
                </b>
                {foundationSuggestions != null && (foundationSuggestions.map(foundation => (
                    <div key={foundation.id} className="col-lg-4 col-md-6 d-flex align-items-stretch"
                         data-aos="fade-up">
                        <CommonCard
                            title={foundation.name}
                            thumbnail={foundation.thumbnail}
                            path={`/foundations/${foundation.id}`}
                        />
                    </div>
                )))}
            </CardContainer>
            <CommonGathaContainer>
                <Divider/>
                <p data-aos="fade-up"
                    className={classes.commonGathaText}
                    style={{
                        fontFamily: 'FMEmanee',
                        fontSize: '2rem',
                        color: '#ff6a00'
                    }}>
                    wmmld f; ukqfiiiq
                    fh ckd mdr.dñfkd -
                    w:dhx b;rd mcd
                    ;Srfïjd kqOdj;s
                </p>
                <p data-aos="fade-down"
                    style={{
                        fontFamily: 'FMBasuru',
                        fontSize: '1.3rem',
                        color: '#033772',
                    }}>
                    ^iir ihqf¾& mrf;rg hkafkda ñksiqka w;frys ÿ¾,N fj;s ^iaj,am fofkls&" wkH jQ jeä fokd fuf;r wkqju ÿj;a
                </p>
                <Divider/>
            </CommonGathaContainer>
            <p data-aos="zoom-out-down"
               style={{
                   fontSize: '3rem',
                   color: '#036183',
                   fontFamily: 'FMBasuru',
                   margin: 0,
               }}
            >jHdmD;s iy jevigyka</p>
            <MainSectionEvents/>
            <Contacts/>
        </>
    )
}