import {API_CONFIGS} from '../../config';
import {BFF_ENDPOINTS} from '../../utils/constants';
import { _get } from '../fetch-api';

export const getContentWrapContainerById = (callback, errorCallback, id) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.getContentWrapContainerById}/${id}`;
  _get(callback, endpointURL, {}, null, errorCallback);
};

export const getContentWrapContainerSuggestions = (callback, errCallBack, parentId) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.getContentWrapContainerSuggestions}/${parentId}`;
  _get(callback, endpointURL, {}, null, errCallBack);
};
