import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { loadMonasteryById } from "../../../client/monastery/call";
import { PageBodyContent } from "../../ui/inngerPageContent/pageBodyContent";
import { FloatingCircle } from "../../ui/animation/floatingCircle";
import { LoginWrapper } from "../../ui/layout/loginWrapper";
import { PageLayout } from "../../ui/layout/pageLayout";

export default function Monasteries() {
    const params = useParams()
    const [id, setId] = useState(params.id);
    const [webPageContents, setWebPageContents] = useState(null)

    useEffect(() => {
        setId(params.id)
    },
        [params.id]
    );

    useEffect(() => {
        if (id) {
            setWebPageContents(null)
            loadMonastery()
        }
    }, [id])

    const loadMonastery = () => {
        loadMonasteryById((result) => {
                setWebPageContents(result.content.webPageContents)
            },
            error => {

            }, id)
    }

    return (
        <PageLayout header={"test"} sx={{}}>
            <LoginWrapper
                callback={loadMonastery}
            >
                {webPageContents && (
                    <PageBodyContent
                        parentId={id}
                        containers={webPageContents}
                        parentType={"LOCATION"}
                    />
                )}
            </LoginWrapper>
        </PageLayout>
    );
}