const TOKEN_KEY = 'sdsdfghjghj55';
const AN_TK = '34jdDDPSTsdsdfghjghj55';
const AN_RTK = '34jdDDPSTsJktPdfghjghj55';
const REFRESH_KEY = 's5df848652d';
const EXPIRE_IN = '55sdfLO554eSD215';
const AN_EXPIRE_IN = '55sdf554e215';
const START_TIME = '5sLd5f42R12asdF5f4';
const AN_START_TIME = '5sd5f4212asd5f4';
const USER_ID = '8847dkudlk3kjdkls';
const USER_TYPE = '74002373088jdskjsdu';

const saveToken = (accessToken, refreshToken, expiresIn, userId, userType) => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.setItem(TOKEN_KEY, accessToken);
  localStorage.removeItem(REFRESH_KEY);
  localStorage.setItem(REFRESH_KEY, refreshToken);
  localStorage.removeItem(EXPIRE_IN);
  localStorage.setItem(EXPIRE_IN, expiresIn + '');
  localStorage.removeItem(START_TIME);
  localStorage.setItem(START_TIME, new Date().getTime() + '');
  localStorage.removeItem(USER_ID);
  localStorage.setItem(USER_ID, userId);
  localStorage.removeItem(USER_TYPE);
  localStorage.setItem(USER_TYPE, userType);
};

const saveAnToken = (accessToken, refreshToken, expiresIn) => {
  localStorage.removeItem(AN_TK);
  localStorage.setItem(AN_TK, accessToken);
  localStorage.removeItem(AN_RTK);
  localStorage.setItem(AN_RTK, refreshToken);
  localStorage.removeItem(AN_EXPIRE_IN);
  localStorage.setItem(AN_EXPIRE_IN, expiresIn + '');
  localStorage.removeItem(AN_START_TIME);
  localStorage.setItem(AN_START_TIME, new Date().getTime() + '');
};

const saveRefreshToken = token => {
  localStorage.removeItem(REFRESH_KEY);
  localStorage.setItem(REFRESH_KEY, token);
};

const saveAnRefreshToken = token => {
  localStorage.removeItem(AN_RTK);
  localStorage.setItem(AN_RTK, token);
};

const signOut = () => {
  localStorage.clear();
};

const getAccessToken = () => {
  return localStorage.getItem(TOKEN_KEY);
};

const getAnAccessToken = () => {
  return localStorage.getItem(AN_TK);
};

const getRefreshToken = () => {
  return localStorage.getItem(REFRESH_KEY);
};

const getAnRefreshToken = () => {
  return localStorage.getItem(AN_RTK);
};

const getExpireIn = () => {
  return +localStorage.getItem(EXPIRE_IN);
};

const getAnExpireIn = () => {
  return +localStorage.getItem(AN_EXPIRE_IN);
};

const getUserId = () => {
  return localStorage.getItem(USER_ID);
};

const getUserType = () => {
  return localStorage.getItem(USER_TYPE);
};

const getStartTime = () => {
  return +localStorage.getItem(START_TIME);
};

const getAnStartTime = () => {
  return +localStorage.getItem(AN_START_TIME);
};

export {
  saveToken,
  saveAnToken,
  saveRefreshToken,
  saveAnRefreshToken,
  getAccessToken,
  getAnAccessToken,
  getRefreshToken,
  getAnRefreshToken,
  getExpireIn,
  getAnExpireIn,
  getStartTime,
  getAnStartTime,
  getUserId,
  getUserType,
  signOut,
};
