import {Box, Typography, Divider, Drawer,} from "@mui/material";
import * as React from "react";
import DrawerNavigationItems from "./drawerNavigationItems";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../context/appContext";

export default function MainDrawer(props) {

    const {handleDrawerToggle, drawerWidth, open} = props

    const {monasterySuggestions, foundationSuggestions} = useContext(AppContext)

    const [wildMonasteries, setWildMonasteries] = useState([])
    const [monasteries, setMonasteries] = useState([])
    const [hospitalityMonasteries, setHospitalityMonasteries] = useState([])
    const [shasanikaServicesFoundations, setShasanikaServicesFoundations] = useState([])
    const [socialServicesFoundations, setSocialServicesFoundations] = useState([])

    useEffect(() => {
        let wildMon = [];
        let mon = [];
        let hosMon = [];
        monasterySuggestions.forEach(monastery => {
            if (monastery.type === "WMO") {
                wildMon.push(monastery);
            } else if (monastery.type === "MON") {
                mon.push(monastery);
            } else if (monastery.type === "HOM") {
                hosMon.push(monastery);
            }
        });
        setWildMonasteries(wildMon)
        setMonasteries(mon)
        setHospitalityMonasteries(hosMon)
    }, [
        monasterySuggestions
    ])

    useEffect(() => {
        let shaFound = [];
        let socialFound = [];
        foundationSuggestions.forEach(foundation => {
            if (foundation.type === "SHS") {
                shaFound.push(foundation);
            } else if (foundation.type === "SOS") {
                socialFound.push(foundation);
            }
        });
        setShasanikaServicesFoundations(shaFound)
        setSocialServicesFoundations(socialFound)
    }, [
        foundationSuggestions
    ])

    const drawer = (
        <Box sx={{textAlign: 'center'}}>
            <Typography
                sx={{
                    my: 2,
                    fontFamily: 'FMSamantha',
                    fontSize: '2rem',
                    color: 'primary.main'
                }}
            >
                mdidÈl
            </Typography>
            <Divider/>
            <DrawerNavigationItems
                wildMonasteries={wildMonasteries}
                monasteries={monasteries}
                hospitalityMonasteries={hospitalityMonasteries}
                shasanikaServicesFoundations={shasanikaServicesFoundations}
                socialServicesFoundations={socialServicesFoundations}
                open={open}
            />
        </Box>
    );

    return (
        <Box component="nav">
            <Drawer
                variant="temporary"
                open={open}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: {xs: 'block', sm: 'none'},
                    '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                }}
            >
                {drawer}
            </Drawer>
        </Box>
    )
}