import {Box, Typography} from "@mui/material";

export const CardContainer = (props) => {

    return (
        <Box
            sx={{
                margin: '2rem',
            }}
        >
            <span data-aos="fade-in">
            <Typography
                fontFamily={props.headerFont ? props.headerFont : "FMArjunn"}
                fontSize={'2rem'}
                color={props.headerColor ? props.headerColor :
                    '#26503e'}
            >
                {props.header}
            </Typography>
            </span>
            <Box sx={{
                margin: '.5rem',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'center',
            }}>
                {props.children}
            </Box>
        </Box>
    )
}