import {useContext, useState} from 'react';

import {AccountCircle} from "@mui/icons-material";
import {IconButton, Menu, MenuItem, Typography} from "@mui/material";

import {logout} from "../../../client/authentication/auth";
import {toast} from "react-toastify";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import * as React from "react";
import {AppContext} from "../../../context/appContext";
import {useNavigate} from "react-router-dom";


export default function LoginMenu(props) {

    const [anchorEl, setAnchorEl] = useState(null);
    const {auth, authenticated} = useContext(AppContext)
    const navigate = useNavigate()

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        setAnchorEl(null);
        logout(() => {
            toast(`User logout success!`,
                {
                    closeOnClick: true,
                    type: "success",
                    theme: "light",
                    icon: <CheckCircleOutlineIcon/>
                });
            authenticated({})
            props.onClickLogoutButton()
            navigate(`/home`);
        })
    };

    return (<div>
            <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
            >
                <AccountCircle/>
                <Typography fontFamily={"Roboto"} variant={"subtitle1"}> &nbsp;{auth? auth.user_name : ""}</Typography>
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem
                    sx={{fontFamily: "Roboto"}}
                    onClick={handleLogout}>
                    Logout
                </MenuItem>
                <MenuItem sx={{fontFamily: "Roboto"}} onClick={handleClose}>My account</MenuItem>
            </Menu>
        </div>
    )
}